.overview {
  margin: 2rem 4rem;
  color: rgb(6, 6, 146);
}

.overview h1 {
  font-size: 2.65rem;
}
.overview p {
  font-size: 1.2rem;
  padding-bottom: 3rem;
}

.overview-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.history-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.mission-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.karigor-mission {
  display: flex;
}

.karigor-mission img {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.history-text {
  width: 60%;
  text-align: start;
  font-size: 1rem;
  padding-left: 4rem;
}

.mission-text {
  width: 60%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
  margin-right: 4rem;
}

.mission-statement {
  color: rgb(15, 110, 198);
  font-weight: bold;
  font-style: italic;
}

.overview-text {
  width: 60%;
  text-align: start;
  font-size: 1rem;
  padding-right: 4rem;
}
.overview-text h2,
.mission-text h2,
.history-text h2 {
  padding-bottom: 1rem;
  justify-content: center;
  font-size: 1.6rem;
}
.overview-text p,
.mission-text p,
.history-text p {
  text-align: justify;
  font-size: 1.2rem;
}

.home-image {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.mission-image {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.history-image {
  position: relative;
  width: 60%;
  justify-content: space-between;
  z-index: -99;
}

.history-image-2 {
  margin-top: 2rem;
}

.home-image img {
  width: 49%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.mission-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.history-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.home-image img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}

@media screen and (max-width: 850px) {
  .overview {
    margin: 1rem 1rem;
  }
  .overview h1 {
    font-size: 1.3rem;
  }
  .overview p {
    padding-bottom: 0.7rem;
    font-size: 1rem;
  }

  .overview-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 1rem;
    width: 100%;
  }

  .history-des {
    display: flex;
    flex-direction: column;
    margin-top: 0;
  }

  .mission-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0.5rem;
    padding-left: 0;
  }

  .home-image,
  .mission-image,
  .history-image {
    width: 100%;
    margin: 1.5rem 0;
  }

  .history-text {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
  .overview-text {
    margin-left: -4rem;
  }

  .overview-text p {
    width: 110%;
    padding-right: 0;
  }
  .overview-text h2,
  .mission-text h2,
  .history-text h2 {
    justify-content: space-evenly;
    font-size: 1.3rem;
  }

  .mission-text {
    width: 100%;
    text-align: start;
    border-radius: 7px;
    margin-right: 0;
  }

  .karigor-mission img {
    width: 2rem;
    height: 2rem;
    margin-right: 1.5rem;
  }
}
