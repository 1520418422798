.event-submenu {
  margin-top: 1.2rem;
  width: 10.2rem;
  position: absolute;
  list-style: none;
  text-align: start;
  padding: 1rem 0.3rem;
  background: rgb(31, 101, 101);
  border: solid rgb(31, 101, 101);
  border-radius: 1rem;
}

.dropdown-links {
  text-decoration: none;
  color: whitesmoke;
  font-size: 0.9rem;
  font-weight: 600;
  padding: 0.7rem 0.3rem;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}
.dropdown-links:hover {
  color: goldenrod;
}

#event-drop-menu {
  margin-left: 0.5rem;
}

#event-menu {
  text-decoration: none;
  color: whitesmoke;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.7rem 1rem;
  white-space: nowrap;
}

#event-menu:hover {
  color: goldenrod;
}
.event-submenu li {
  padding: 0.5rem;
}

.event-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 3rem;
  display: block;
}

@media screen and (max-width: 850px) {
  .event-submenu {
    margin-top: -2rem;
    width: 8rem;
    padding: 0.5rem 0.3rem;
    background: rgb(31, 101, 101);
    border: solid rgb(31, 101, 101);
  }

  #event-menu {
    text-decoration: none;
    color: navy;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.7rem 0.6rem;
    white-space: nowrap;
  }
  .dropdown-links {
    text-decoration: none;
    color: whitesmoke;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.5rem 0.1rem;
    white-space: nowrap;
    width: 100%;
    height: 100%;
  }
  .dropdown-links:hover {
    background: none;
    color: goldenrod;
    display: block;
  }
}
