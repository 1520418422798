.ekCupCha-heading {
  margin-top: 2rem;
  font-size: 2.5rem;
  color: rgb(6, 6, 146);
}

.ekCupCha-container {
  margin: 2rem 4rem;
  color: rgb(6, 6, 146);
  text-align: justify;
  font-size: 1.2rem;
}

.ekCupCha-heading-expl {
  color: navy;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.ekCupCha-des-heading {
  text-align: start;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.ekCupCha-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ekCupCha-text {
  text-align: start;
  width: 70%;
  font-size: 1.1rem;
  padding-right: 4rem;
}

.ekCupCha-text p {
  text-align: justify;
  font-size: 1.2rem;
}

#ekCupCha-image {
  position: relative;
  width: 100%;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  z-index: -99;
}
#ekCupCha-image img {
  width: 100%;
  height: auto;
  object-fit: cover;

  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.ekCupCha-host-info {
  margin-top: 1rem;
}

.ekCupCha-cart {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.ekCupCha-episode {
  width: 25%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
}

.ekCupCha-episode h3 {
  font-size: 1.2rem;
}

.ekCupCha-episode p {
  font-size: 1.1rem;
}

.ekCupCha-episode-image {
  height: auto;
  overflow: hidden;
  border-radius: 7px;
}

.ekCupCha-episode-image img {
  width: 100%;
  height: auto;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.ekCupCha-episode-image:hover img {
  transform: scale(1.3);
}

.episode-link {
  text-decoration: none;
  cursor: pointer;
  color: navy;
}

@media screen and (max-width: 850px) {
  .ekCupCha-heading {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }
  .ekCupCha-heading-expl {
    font-size: 1rem;
  }

  .ekCupCha-container {
    margin: 1.5rem 2rem;
    font-size: 1rem;
  }
  .ekCupCha-des {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .ekCupCha-text {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }

  .ekCupCha-text p {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .ekCupCha-cart {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .ekCupCha-episode {
    width: 100%;
  }

  .ekCupCha-container {
    margin: 4rem 2rem;
  }
  .ekCupCha-episode h3 {
    font-size: 1rem;
  }

  .ekCupCha-episode p {
    font-size: 1rem;
  }
}
