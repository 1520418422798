.kpNews-heading {
  margin-top: 2rem;
  font-size: 2.5rem;
  color: rgb(6, 6, 146);
}

.kpNews-heading-expl {
  color: navy;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.kpNews-container {
  margin: 2rem 4rem;
  color: rgb(6, 6, 146);
  text-align: justify;
  font-size: 1.2rem;
}

.kpNews-des-heading {
  justify-content: start;
  font-size: 1.5rem;
}

.kpNews-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.kpNews-intro {
  display: flex;
}

.kpNews-text {
  width: 60%;
  text-align: start;
  padding-left: 4rem;
}

.kpNews-text p {
  text-align: justify;
  font-size: 1.2rem;
  padding-top: 1.5rem;
}

#kpNews-image {
  position: relative;
  width: 100%;
  height: 25rem;
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  z-index: -99;
  margin-top: 2rem;
}
#kpNews-image img {
  width: 100%;
  object-fit: cover;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.kpNews-cart-heading {
  padding-top: 3rem;
  justify-content: start;
  font-size: 1.5rem;
}
.kpNews-cart {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}
.kpNews-episode {
  width: 30%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
}

.kpNews-episode h3 {
  font-size: 1.2rem;
}

.kpNews-episode p {
  font-size: 1.2rem;
}

.kpNews-episode-image {
  height: auto;
  overflow: hidden;
  border-radius: 7px;
}

.kpNews-episode-image img {
  width: 100%;
  height: auto;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.kpNews-episode-image:hover img {
  transform: scale(0.9);
}

@media screen and (max-width: 850px) {
  .kpNews-heading {
    margin-top: 1.2rem;
    font-size: 1.6rem;
  }

  .kpNews-heading-expl {
    font-size: 1rem;
  }

  .kpNews-container {
    margin: 1.5rem 2rem;
    font-size: 1rem;
  }

  .kpNews-des-heading {
    font-size: 1.3rem;
  }

  .kpNews-des {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .kpNews-intro {
    display: flex;
    flex-direction: column-reverse;
  }

  .kpNews-text {
    width: 100%;
    padding-left: 0;
  }

  .kpNews-text p {
    font-size: 1rem;
    padding-top: 1rem;
  }

  .kpNews-episode h3 {
    font-size: 1rem;
  }

  .kpNews-episode p {
    font-size: 1rem;
  }

  .kpNews-cart {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .kpNews-episode {
    width: 100%;
  }

  #kpNews-image {
    height: auto;
    margin-top: 1rem;
  }
  .kpNews-cart-heading {
    padding-top: 1rem;
    font-size: 1.3rem;
  }
}
