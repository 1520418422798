.home-event {
  margin: 3rem 6rem;
  color: rgb(4, 4, 128);
}

.home-event h1 {
  font-size: 2.5rem;
}
.home-event p {
  font-size: 1.2rem;
}

.home-event-cart {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
}
.homeEventDataCart {
  width: 24%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
}

.homeEventDataCart-image {
  height: 170px;
  overflow: hidden;
  border-radius: 7px;
}

.homeEventDataCart-image img {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.homeEventDataCart-image:hover img {
  transform: scale(1.3);
}
.homeEventDataCart p {
  font-size: 1.2rem;
  padding: 0.9rem 0 0.2rem 0;
}

.event-button {
  background: transparent;
  width: 100%;
  padding: 1rem;
  color: navy;
  border-radius: 0.5rem;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.2rem;
  width: 100%;
  margin-top: 2rem;
  border: solid #99c3dd;
}
.event-button:hover {
  background: rgb(230, 237, 227);
  transform: scale(1.1);
  cursor: pointer;
}

@media screen and (max-width: 850px) {
  .home-event {
    margin: 2rem 2rem;
  }

  .home-event h1 {
    font-size: 1.3rem;
  }

  .home-event-cart {
    flex-direction: column;
    margin-top: 1.5rem;
  }

  .home-event-cart h2 {
    font-size: 1rem;
  }

  .homeEventDataCart {
    width: 100%;
    margin-bottom: 1rem;
  }
  .homeEventDataCart-image {
    height: auto;
  }

  .home-event p {
    font-size: 1rem;
  }

  .event-button {
    font-size: 1rem;
  }
}
