.upcoming-container {
  padding: 2rem 4rem;
  color: navy;
}

.upcoming-container h1 {
  font-size: 2.5rem;
}
.upcoming-container h2 {
  font-size: 2rem;
  margin-top: 1rem;
}
.upcoming-container h3 {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.upcoming-container p {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  font-style: italic;
}

.upcoming-event img {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 850px) {
  .upcoming-container {
    padding: 1rem 1.5rem;
  }
  .upcoming-container h1 {
    font-size: 1.5rem;
  }
  .upcoming-container h2 {
    font-size: 1.3rem;
  }
  .upcoming-container h3 {
    font-size: 1.15rem;
  }
  .upcoming-container p {
    font-size: 1rem;
  }
}
