.membership-container {
  padding: 2rem 8rem;
  color: navy;
}

.membership-container h1 {
  font-size: 2.5rem;
}

.membership-container p {
  margin-top: 1rem;
  margin-bottom: 2.5rem;
  font-size: 1.25rem;
  font-style: italic;
}

.membership-form img {
  width: 100%;
}

@media screen and (max-width: 850px) {
  .membership-container {
    padding: 1.5rem 2rem;
    color: navy;
  }
  .membership-container h1 {
    font-size: 1.3rem;
  }

  .membership-container p {
    font-size: 1rem;
  }
}
