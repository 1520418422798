.about-container {
  margin: 2rem 1.5rem;
  color: rgb(6, 6, 146);
  padding: 2rem;
}

.vision-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.objective-des {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3.5rem;
}

.vision-text {
  width: 60%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
  margin-left: 4rem;
}

.objective-text {
  width: 60%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
  margin-right: 4rem;
  padding-left: 3rem;
}

.objective-text h2,
.vision-text h2 {
  padding-bottom: 1rem;
  justify-content: center;
  font-size: 1.6rem;
}

.objective-text p,
.vision-text p {
  text-align: justify;
  font-size: 1.2rem;
}

.objective-list {
  padding-bottom: 1rem;
}

.objective-list li {
  padding: 0.4rem;
}
.vision-image {
  position: relative;
  width: 60%;
  justify-content: space-between;
  z-index: -99;
}
.objective-image {
  position: relative;
  width: 60%;
  display: flex;
  justify-content: space-between;
  z-index: -99;
}

.vision-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.objective-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.members-heading {
  margin-top: 3rem;
  font-size: 2rem;
  color: rgb(6, 6, 146);
}

.members-heading-subTitle {
  color: navy;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.kp-members-heading-gallery {
  padding-top: 2rem;
  /* justify-content: start; */
  align-items: start;
  font-size: 1.5rem;
}

.kp-members-cart {
  margin-top: 2rem;
  display: flex;
  /* justify-content: space-between; */
  justify-content: start;
}
.kp-members-cart h3 {
  font-size: 1.2rem;
}

.kp-members-cart p {
  font-size: 1.2rem;
}

.kp-members-area {
  width: 20%;
  text-align: start;
  padding: 1rem;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.19);
  border-radius: 7px;
  cursor: pointer;
}

.kp-members-area-image {
  width: auto;
  overflow: hidden;
  border-radius: 7px;
}

.kp-members-area-image img {
  width: 100%;
  height: auto;
  transition: 0.3s ease-in-out;
  border-radius: 7px;
}

.kp-members-area-image:hover img {
  transform: scale(1.3);
}

@media screen and (max-width: 850px) {
  .about-container {
    margin: 0.5rem 1.5rem;
    padding: 0.3rem;
  }
  .overview {
    margin: 1.5rem 1.5rem;
  }
  .overview p {
    padding-bottom: 1rem;
  }

  .objective-des {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 2rem;
    padding-left: 0rem;
  }
  .vision-des {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
  }

  .home-image,
  .vision-image {
    width: 100%;
    margin: 1.5rem 0;
  }

  .objective-image {
    width: 100%;
  }

  .overview-text {
    width: 100%;
    padding-right: 0;
    padding-left: 2rem;
  }

  .vision-text {
    width: 100%;
    text-align: start;
    border-radius: 7px;
    margin-left: 0;
  }

  .vision-text p {
    font-size: 1rem;
  }

  .objective-text {
    width: 100%;
    text-align: start;
    border-radius: 7px;
    margin-left: 4rem;
    margin-top: 2rem;
  }

  .objective-text p {
    font-size: 1rem;
  }

  .members-heading {
    margin-top: 1.5rem;
    font-size: 1.5rem;
  }

  .cultural-des-heading-gallery {
    padding-top: 1rem;
    justify-content: start;
    font-size: 1.2rem;
  }

  .kp-members-cart {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
  }

  .kp-members-cart h3 {
    font-size: 1rem;
  }

  .kp-members-cart p {
    font-size: 1rem;
  }

  .kp-members-area {
    width: 100%;
  }
}
